import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {ITelemetryPlugin} from "@microsoft/applicationinsights-core-js/types/JavaScriptSDK.Interfaces/ITelemetryPlugin";

const {config} = window.galen.cc.vco;

const appInsights = new ApplicationInsights({
    config: {
        enableDebug: true,
        instrumentationKey: config.instrumentationKey,
        extensions: [],
        extensionConfig: {}, // can be configured by calling the init/finalization method defined in this module. Otherwise, no defaults currently.
        isStorageUseDisabled: false, //	If true, the SDK will not store or read any data from local and session storage.
        enableAjaxErrorStatusText: false, // If true, include response error data text in dependency event on failed AJAX requests
        enableAjaxPerfTracking: false, // Flag to enable looking up and including additional browser window.performance timings in the reported ajax (XHR and fetch) reported metrics.
        // The maximum number of times to look for the window.performance timings (if available),
        // this is required as not all browsers populate the window.performance before reporting
        // the end of the XHR request and for fetch requests this is added after its complete.
        maxAjaxPerfLookupAttempts: 3,
        ajaxPerfLookupDelay: 25, // The amount of time to wait before re-attempting to find the window.performance timings for an ajax request, time is in milliseconds and is passed directly to setTimeout().
        isBeaconApiDisabled: false, // If false, the SDK will send all telemetry using the Beacon API
        onunloadDisableBeacon: false, // When tab is closed, the SDK will send all remaining telemetry using the Beacon API
        // Automatically track route changes in Single Page Applications (SPA). If true, each
        // route change will send a new Pageview to Application Insights. Hash route changes
        // (example.com/foo#bar) are also recorded as new page views.
        enableAutoRouteTracking: false,
        enableRequestHeaderTracking: false, // If true, AJAX & Fetch request headers is tracked.
        enableResponseHeaderTracking: false // If true, AJAX & Fetch request's response headers is tracked
    }
});

/*
SessionTelemetryInitializer - Replace with one that sets the session value to account ID + date/time authenticated? what about f5? we can sett in the ai_session cookie? and preven it from updating?
UserTelemetryInitializer  - same?
 */

// We'll update this to be a subset of App Insights.
export type VcoApplicationInsights = ApplicationInsights;
export const vcoAppInsights: VcoApplicationInsights = appInsights;

// By wrapping the last step in a function call, different pages or apps to provide their own extensions.
export const finalizeAndLoadGalenAppInsights = (extensions: ITelemetryPlugin[], extensionsConfig: { [key: string]: unknown }) => {
    // Apply, and potentially overwrite, the extension configuration
    appInsights.config.extensions = (appInsights.config.extensions ?? []).concat((extensions ?? []));
    appInsights.config.extensionConfig = {...appInsights.config?.extensionConfig, ...extensionsConfig};
    appInsights.loadAppInsights();
};